import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import ListSolutions from '../components/ListSolutions'

export const query = graphql`
{
  markdownRemark(fileAbsolutePath: {regex: "/(solutions/_index)/"}) {
    frontmatter {
      seo {
        seo_description
        seo_title
      }
      welcome {
        welcome_content
        welcome_title
        welcome_image
      }
    }
  }
}
`

const Solutions = ({ data }) => {
  const SolutionsData = data.markdownRemark.frontmatter
  
  return(
    <Layout>
      <SEO
        title={ SolutionsData.seo.seo_title  }
        description={ SolutionsData.seo.seo_description }/>
      
      <Welcome
        title={ SolutionsData.welcome.welcome_title }
        content={ SolutionsData.welcome.welcome_content }
        image={ SolutionsData.welcome.welcome_image } />

      <ListSolutions />
    </Layout>
  )
}


export default Solutions

