import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Image from '../Helpers/image'

import './list-solutions.scss'

const ListSolutions = (props) => (
    <StaticQuery
        query={graphql`
        {
          allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(solutions-pages)/"}}, sort: {fields: frontmatter___order}) {
            edges {
              node {
                id
                frontmatter {
                  title
                  slug
                  description
                  image
                }
              }
            }
          }
        }
        `}
        render={ (data) => {
          console.log(data)
            return(
              <div className="list-solutions">
                {data.allMarkdownRemark.edges.map( edge  => (
                  <div className="list-solutions__item" key={edge.node.id}>
                      
                      <figure>
                        <Image filename={ edge.node.frontmatter.image } />
                      </figure>

                      <h3>{ edge.node.frontmatter.title }</h3>
                      <div className="list-solutions__description">
                          { edge.node.frontmatter.description }
                      </div>

                      <div className="list-solutions__link">
                        <Link to={ edge.node.frontmatter.slug }>Veja mais</Link>
                      </div>
                  </div>
                ))}
              </div>
            )
        }}
  ></StaticQuery>
)

export default ListSolutions
